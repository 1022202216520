<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <i class="uil-home-alt"></i> <span> {{ $t('general.reports') }}</span>
            </router-link>
          </li>
          <li>{{ $t('general.survey_answer_reports') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span> {{ $t('general.survey_answer_reports') }} </span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->
    <div v-if="error" class="uk-alert-danger mb-3" uk-alert>
      <a class="uk-alert-close" uk-close></a>
      <p style="color:red;">
        <i class="uil-exclamation-triangle"></i> {{ error }}
      </p>
    </div>
    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <!-- <div>
            <a aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px;"
                 uk-drop="mode: hover; pos: right-center; offset: 0">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                         class="uk-search-input shadow-0 uk-form-small" type="search">
                </span>
            </div>
          </div> -->
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" autofocus="" />
            </div>
          </div>
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'survey_name')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'survey_name')">{{ $t("sorting.z_a") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'id')">
                    {{ $t("sorting.created_at_old") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'id')">
                    {{ $t("sorting.created_at_new") }}</a>
                </li>
              </ul>
            </div>
            <!-- Filter -->
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:void(0)"
              :uk-tooltip="$t('general.filter_list')">
              <i class="uil-filter"></i>
            </a>
            <div id="filterDropdown" class="large"
              uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <div class="filter-container">
                <div class="uk-child-width-1-2@s uk-grid-small" uk-grid>
                  <div>
                    <h5 class="uk-text-bold mb-2">Kullanıcı</h5>
                    <multiselect v-model="user_id" :allow-empty="false" :options="userList" :searchable="true"
                      deselectLabel="" label="name" placeholder="Kullanıcı Seçiniz" selectLabel="Seçiniz"
                      tag-placeholder="Kullanıcı Seçiniz" track-by="id" @input="getAssignments(user_id.id)" />
                  </div>
                  <div>
                    <h5 class="uk-text-bold mb-2">Eğitim</h5>
                    <multiselect v-model="assignment_id" :allow-empty="false" :options="assignmentList"
                      :searchable="true" deselectLabel="" label="name" placeholder="Eğitim Seçiniz"
                      selectLabel="Seçiniz" tag-placeholder="Eğitim Seçiniz" track-by="id">
                      <template slot="option" slot-scope="option">
                        {{ option.option.name }}
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="uk-flex uk-flex-right p-2">
                  <button class="btn btn-default grey" @click="filterResult">
                    <i class="uil-filter"></i>
                    {{ $t("general.filter") }}
                  </button>
                </div>
              </div>
            </div>
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportSurveyResults">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t("users.photo") }}</th>
                <th scope="col">{{ $t('general.first_name_and_last_name') }}</th>
                <th scope="col">{{ $t('general.user_status') }}</th>
                <th scope="col">{{ $t('general.register_number') }}</th>
                <th scope="col">Eğitim</th>
                <th scope="col">{{ $t('general.question') }}</th>
                <th scope="col">{{ $t('general.answer') }}</th>
                <th scope="col">{{ $t('general.answer_point') }}</th>
                <th scope="col">{{ $t('general.average_point') }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in formattedItems">
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <div class="avatar-parent-child" style="width: max-content">
                          <img :alt="item.name" :src="item.photo_link" class="avatar  rounded-circle" />
                        </div>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.name }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.registration_number }}</td>
                  <td>{{ item.assignment_name }}</td>
                  <td>{{ item.survey_name }}</td>
                  <td>{{ item.label }}</td>
                  <td>{{ item.point }}</td>
                  <td>{{ item.avarage?.toFixed(2) }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Multiselect from "vue-multiselect";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportEvaluationSurveyResults",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: { UserProfile, Multiselect, DefaultLoading },
  data() {
    return {
      user_id: null,
      assignment_id: null,
      activeItem: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      userList: [],
      assignmentList: [],
      error: null,
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    exportSurveyResults() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_APP_URL +
          "/api/report/export/user-survey-results",
          {
            responseType: "blob",
            params: {
              user_id: this.user_id ? this.user_id.id : null,
              assignment_id: this.assignment_id ? this.assignment_id.id : null,
              sort: this.filter.sort,
              order: this.filter.order,
              like: this.filter.search,
              report: Math.random() * 9999999
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.secureLocalStorage("token"),
            },
          }
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "survey_result_report_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    filterResult(filter = null) {
      let self = this;
      this.error = null;
      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
        user_id: self.user_id ? self.user_id.id : null,
        assignment_id: self.assignment_id ? self.assignment_id.id : null,
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/user-evaluation-survey-results",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response;
            items.data.forEach((item) => {
              let questions = JSON.parse(item.questions);
              questions.forEach((questionItem) => {
                let questionInfo;
                if (questionItem.type === 1) {
                  questionInfo = questionItem.options.find(
                    (element) => element.point === questionItem.answerPoint
                  );
                } else {
                  questionInfo = {
                    label: questionItem.answerPoint,
                    point: "-",
                  };
                }
                results.push({
                  name: item.user?.fullname,
                  status: (item.user?.status_id == 0 || item.user?.deleted_at != null) ? "Pasif" : "Aktif",
                  registration_number: item.user?.registration_number ?? "-",
                  photo_link: item.user?.photo_link,
                  survey_name: questionItem?.name,
                  assignment_name: item.resultable?.name,
                  label: questionInfo?.label,
                  point: questionInfo?.point,
                  avarage: item.avarage,
                });
              });
            });
            this.formattedItems = results;
          }
        });
    },
    getUsers() {
      let filters = {
        per_page: 0,
        sort: "id",
        order: "desc",
        fields: "id,name,surname",
        with_survey_result: true,
      };

      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/user",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [{
              id: "all",
              name: "Tümü",
            }];
            let items = response.data;
            items.forEach((item) => {
              results.push({
                id: item.id,
                name: item.name + " " + item.surname,
              });
            });
            this.userList = results;
          }
        });
    },
    getAssignments(user_id) {
      let filters = {
        page: 0,
        sort: "id",
        order: "desc",
        user_id: user_id,
        survey: 1,
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/get-assignments",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response.data;
            items.forEach((item) => {
              results.push({
                id: item.id,
                name: item.full_name,
              });
            });
            this.assignmentList = results;
          }
        });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.getUsers();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker1").selectpicker();
        }, 200);
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker2").selectpicker();
        }, 200);
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker3")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker4")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker5")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker6")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker7")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
